import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { CrudService } from 'src/app/services/crud.service';


@Injectable({
    providedIn: 'root'
})
export class ChatService {
    constructor(private socket: Socket, private crudService: CrudService) {
    }

    onConnect() {
        if (this.socket.ioSocket.connected) {
            return;
        }
        try {
            this.socket.ioSocket.io.opts.query = { authorization: `${this.crudService.getAuthToken}`, userType: 'guest', propertyId: this.crudService.slugConfiguration['id'] };
            this.socket.connect();
        } catch (err) {
            console.error(err);
        }
    }

    onDisconnect() {
        this.socket.disconnect();
    }

    sendChat(message) {
        this.socket.emit('chat', message);
    }
    receiveChat() {
        return this.socket.fromEvent('chat');
    }
    getUsers() {
        return this.socket.fromEvent('users');
    }

    public sendMessage({
        conversationId, message
    }) {
        this.socket.emit('send-message', {
            conversationId, message
        });
    }

    public leaveConversation({
        conversationId
    }) {
        this.socket.emit('leave-conversation', {
            conversationId
        });
    }

    public getConversations() {
        return this.socket.fromEvent('get-guest-conversations');
    }

    public getStaticConversations() {
        return this.socket.fromEvent('get-guest-static-conversations');
    }

    public requestConversations() {
        this.socket.emit('request-guest-conversations');
    }

    public requestStaticConversations() {
        this.socket.emit('request-guest-static-conversations');
    }

    public createConversation({
        guestId, roomNumber, title, userId
    }) {
        this.socket.emit('create-conversation', {
            guestId, roomNumber, title, userId
        });
    }

    public startConversation({ conversationId }) {
        this.socket.emit('start-conversation', {
            conversationId
        });
    }

    public getConversationMessages() {
        return this.socket.fromEvent('get-guest-conversation-messages')
    }

    public getMessage() {
        return this.socket.fromEvent('get-message')
    }

    public updateConversion({
        conversationId, endAt
    }) {
        this.socket.emit('update-conversation', {
            conversationId, endAt
        });
    }



}