import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { RoomServiceModal } from "./home/room-service/room-service.page"
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RequestInterceptorService } from './services/request-interceptor.service';
import { HeaderComponent } from './component/header/header.component';
import { FooterComponent } from './component/footer/footer.component';
import { LoginPage } from './login/login.page';
import { LoginPageModule } from './login/login.module';
import { ComponentsModule } from './component/components.module';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { ModalComponent } from './component/modal/modal.component';
import { environment } from 'src/environments/environment';
import { ChatService } from './conversation/chat/chat.service';
const socketConfig: SocketIoConfig = { url: environment.WS_ENDPOINT, options: { autoConnect: false }, };

@NgModule({
  declarations: [AppComponent, RoomServiceModal],
  entryComponents: [HeaderComponent, FooterComponent, ModalComponent],
  imports: [IonicModule, BrowserModule, IonicModule.forRoot(), AppRoutingModule, HttpClientModule, ComponentsModule, AutocompleteLibModule, SocketIoModule.forRoot(socketConfig)],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: RequestInterceptorService,
    multi: true,
  },
    ChatService
  ],
  bootstrap: [AppComponent],
  exports: [AutocompleteLibModule, SocketIoModule]
})
export class AppModule { }
