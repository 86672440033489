import { Component } from '@angular/core';
import { ChatService } from './conversation/chat/chat.service';
import { CrudService } from './services/crud.service';
import { UtilService } from './services/util.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  constructor(private crudService: CrudService,
    private utilService: UtilService,
    private chatService: ChatService) {
    this.utilService.loadSlugConfig()
  }

  ngOnInit() {
  }


}
