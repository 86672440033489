import { Component, OnInit } from '@angular/core';
import { CrudService } from 'src/app/services/crud.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  constructor(private crudService: CrudService) { }

  ngOnInit() { }

  checkWebRtcFeature() {
    if (this.crudService.getPropertyConfig['featureWebRTC'] && this.crudService.getPropertyConfig['featureWebRTC'].value == false) {
      return true
    }
    return false
  }

}
