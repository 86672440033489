import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { Roles, RoomService } from './helper/enumerators';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'demo/demo',
    loadChildren: () => import('./demo/demo/demo.module').then(m => m.DemoPageModule)
  },
  {
    path: 'dialer',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./dialer/dialer.module').then(m => m.DialerPageModule)
  },
  {
    path: 'menu',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./menu/menu.module').then(m => m.MenuPageModule)
  },
  {
    path: 'conversation',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./conversation/conversation.module').then(m => m.ConversationPageModule)
  },
  {
    path: 'conversation/chat',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./conversation/chat/chat.module').then(m => m.ChatPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./token-auth/token-auth.module').then(m => m.TokenAuthPageModule)
  },
  {
    path: 'wakeup',
    loadChildren: () => import('./wakeup/wakeup.module').then(m => m.WakeupPageModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminPageModule)
  },
  {
    path: 'user-home',
    canActivate: [AuthGuardService],
    data: { roles: [Roles.housekeepingAdmin, Roles.housekeeping], pwastaffaccess: [RoomService.adminIndex, RoomService.staffIndex] },
    loadChildren: () => import('./user-home/user-home.module').then(m => m.UserHomePageModule)
  },
  {
    path: 'room-service-request',
    loadChildren: () => import('./room-service-request/room-service-request.module').then(m => m.RoomServiceRequestPageModule)
  },
  {
    path: 'admin-room-service-request',
    loadChildren: () => import('./admin-room-service-request/admin-room-service-request.module').then(m => m.AdminRoomServiceRequestPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
