import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChatService } from 'src/app/conversation/chat/chat.service';
import { CrudService } from 'src/app/services/crud.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  @Input()
  title: string;

  @Input()
  backBtn: string;

  showBackBtn = false

  constructor(private crudService: CrudService,
    private router: Router, private chatService: ChatService) { }

  ngOnInit() {
    if (this.backBtn == 'true') {
      this.showBackBtn = true
    } else {
    }
  }




  logout() {
    this.crudService.setAuthToken = null;
    this.chatService.onDisconnect();
    localStorage.clear();
    this.router.navigate(['login']);
  }


}
