import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { IonicModule } from "@ionic/angular";
import { FooterComponent } from "./footer/footer.component";
import { HeaderComponent } from "./header/header.component";
import { RouterModule } from '@angular/router';
import { ModalComponent } from "./modal/modal.component";

@NgModule({
    declarations: [HeaderComponent, FooterComponent, ModalComponent],
    imports: [IonicModule, CommonModule, FormsModule, RouterModule],
    exports: [HeaderComponent, FooterComponent, ModalComponent]
})
export class ComponentsModule { }