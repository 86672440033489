import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { CrudService } from './crud.service';
import { UtilService } from './util.service';


@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

    public activatedEmitter = new BehaviorSubject<boolean>(false);     // contains user's activation status
    public obs$ = this.activatedEmitter.asObservable();     // subscribes to the changes in user'sauth status

    constructor(private router: Router,
        private crudService: CrudService,
        private utilService: UtilService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {


        if (this.crudService.getPwaStaffAccess) {

        } else {
            this.crudService.setPwaStaffAccess = JSON.parse(localStorage.getItem('pwastaffaccess'))
        }

        if (this.crudService.getAuthToken) {
        } else if (localStorage.getItem('token')) {
            this.crudService.setAuthToken = localStorage.getItem('token')
        }

        if (this.crudService.getPwaStaffAccess) {
            if (route.data && route.data.pwastaffaccess) {
                for (let index = 0; index < route.data.pwastaffaccess.length; index++) {
                    const element = route.data.pwastaffaccess[index];
                    if (this.utilService.validateAccess(element) === true) {
                        return true
                    }
                }
            }
            this.router.navigate(['admin']);
            return false;
        } else {
            if (this.crudService.getAuthToken) {
                return true;
            } else if (localStorage.getItem('token')) {
                this.crudService.setAuthToken = localStorage.getItem('token')
                return true;
            }
        }

        this.router.navigate(['login']);
        return false;
    }
}
