import { Component } from '@angular/core';
import { NavController, NavParams } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { CrudService } from 'src/app/services/crud.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
    selector: 'room-service-modal',
    templateUrl: 'room-service.html',
    styleUrls: ['room-service.scss']
})
export class RoomServiceModal {

    propertyId: string = ''
    room: string = ''
    buttons: any = []
    html: string = ''

    constructor(public navCtrl: NavController,
        public modalCtrl: ModalController,
        public crudService: CrudService,
        public utilService: UtilService,
        public navParams: NavParams) {
        if (navParams.get("buttons")) {
            this.buttons = navParams.get("buttons");
        } else if (navParams.get("html")) {
            this.html = navParams.get("html");
        }

    }

    ngOnInit() {
        this.propertyId = localStorage.getItem('propertyId')
        this.room = localStorage.getItem('room')
    }

    dismiss() {
        this.modalCtrl.dismiss();
    }

    async requestRoomService(roomServiceLabel: string) {
        this.utilService.showLoader()
        let requestBody = {
            request: roomServiceLabel,
            propertyId: this.propertyId,
            roomNumber: this.room
        };
        let response = await this.crudService.saveData('room-service', requestBody).toPromise();
        this.utilService.hideLoader()
        this.utilService.showToast(response.response_data);
    }

}
