import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { LoadingController } from '@ionic/angular';
import { CrudService } from './crud.service';


@Injectable({
    providedIn: 'root'
})
export class UtilService {

    private myToast: any;

    conversationtype = [{
        title: "Front Desk",
        media: "../assets/icon/front-desk-90.png"
    }, {
        title: "Housekeeping",
        media: "../assets/icon/cleaning.png"
    }, {
        title: "Concierge",
        media: "../assets/icon/concierge.png"
    }, {
        title: "Spa",
        media: "../assets/icon/foot-massage.png"
    }]

    constructor(
        private toast: ToastController,
        private loadingController: LoadingController,
        private crudService: CrudService
    ) { }

    showToast(message: string) {
        this.myToast = this.toast.create({
            message: message,
            duration: 2000
        }).then((toastData) => {
            toastData.present();
        });
    }

    HideToast() {
        this.myToast = this.toast.dismiss();
    }

    showHideAutoLoader(msg: string, time: number) {
        this.loadingController.create({
            message: msg,
            duration: time
        }).then((res) => {
            res.present();
            res.onDidDismiss().then((dis) => {
                console.log(`Loading dismissed! after ${time} milliseconds`);
            });
        });

    }

    showLoader() {
        this.loadingController.create({
            message: 'Please wait...'
        }).then((res) => {
            res.present();
        });

    }

    hideLoader() {
        this.loadingController.dismiss().then((res) => {
            if (!res) {
                this.hideLoader()
            }
        }).catch((error) => {
            console.log('error', error);
        });
    }

    async loadSlugConfig() {
        const selfUrl = window.location.href
        const { hostname } = new URL(selfUrl)
        const [subdomain] = hostname.split('.')
        let slugConfig = await this.crudService.getData(`property/slug-config/${subdomain}`).toPromise()
        this.crudService.slugConfiguration = slugConfig.response_data
    }

    async loadPropertyConfig() {
        let propertyConfig = await this.crudService.getData(`configuration/configuration-auth/property`).toPromise()
        this.crudService.setPropertyConfig = propertyConfig.configuration
        console.log(this.crudService.getPropertyConfig)
    }

    validateAccess(route: string) {
        const pwastaffaccess = this.crudService.getPwaStaffAccess
        var exists = Object.keys(pwastaffaccess).some((k) => {
            return pwastaffaccess[k]['accessKey'] === route;
        });
        return exists
    }

    checkFeature(property: string) {
        if (this.crudService.getPropertyConfig[property] && this.crudService.getPropertyConfig[property].value == true) {
            return true
        }
        return false
    }

    extractContent(html) {
        const span = document.createElement('span');
        span.innerHTML = html;
        return span.textContent || span.innerText;
    };
}
