export enum Roles {
  housekeepingAdmin = 'housekeeping-admin',
  housekeeping = 'housekeeping'
}

export enum RoomService {
  adminIndex = 'pwa:admin:room-service:index',
  staffIndex = 'pwa:housekeeping:index'
}

